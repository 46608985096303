import React from 'react'
import styled from 'styled-components'

// Elements
import ButtonTransparentArrow from 'components/elements/Buttons/ButtonTransparentArrow'
import Blogik, { BlogConsumer } from 'components/shared/Blogik'
import ButtonDefaultArrow from 'components/elements/Buttons/ButtonDefaultArrow'

// Images
import E from 'img/e.inline.svg'
import Employment from 'img/employment.inline.svg'
import Level from 'img/level.inline.svg'

const StyledHighlightedVacancies = styled.section`
  & h2 {
    color: ${(props) => props.theme.color.dark};
    font-size: ${(props) => props.theme.font.size.subheading};
  }
`

interface HighlightedVacanciesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_HighlightedVacancies
}

const HighlightedVacancies: React.FC<HighlightedVacanciesProps> = ({ fields }) => (
  <StyledHighlightedVacancies>
    <div className="container py-lg-5 py-3">
      <h2 className="text-center">{fields.title}</h2>
      <div className="mt-5">
        <Blogik
          type="vacatures"
          settings={{
            id: 'latest-Vacatures',
            type: 'vacatures',
            postIds: [fields.vacancies![0]?.databaseId,fields.vacancies![1]?.databaseId],
          }}
        >
          <BlogConsumer>
            {(context: any) => (
              <div className="row">
                <div className="col-md-6 d-flex flex-grow-1 mb-5 mb-md-0">
                  {context.posts[0] && <Post fields={context.posts[0].node} />}
                </div>
                <div className="col-md-6 d-flex flex-grow-1">
                  {context.posts[1] && <Post fields={context.posts[1].node} />}
                </div>
              </div>
            )}
          </BlogConsumer>
        </Blogik>
      </div>
      <div className="d-flex justify-content-center mt-5">
        <ButtonDefaultArrow to="/vacatures">
          Bekijk alle vacatures
        </ButtonDefaultArrow>
      </div>
    </div>
  </StyledHighlightedVacancies>
)

const StyledPost = styled.div`
  background-color: ${(props) => props.theme.color.light};
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  z-index: 1;
  padding: 40px 40px 20px 40px;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: ${(props) => props.theme.color.secondary};
    font-weight: ${({ theme }) => theme.font.weight.light};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  & p {
    color: ${(props) => props.theme.color.alternate};
    line-height: 30px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 0;
  }

  @media screen and (max-width: 991px) {
    padding: 30px 30px 20px 30px;
  }
`

interface PostProps {
  fields: {
    title: string
    vacaturedetail: {
      info: {
        employment: string
        jobdescription: string
        level: string
        salary: string
      }
    }
    vacatureTypes: any
    uri: string
  }
}

const Post: React.FC<PostProps> = ({ fields }) => {
  const { info } = fields.vacaturedetail

  const type = fields.vacatureTypes.nodes[0].name

  return (
    <StyledPost>
      <h4>{fields?.title}</h4>
      <div className="d-flex flex-column flex-lg-row justify-content-between my-lg-4 my-3">
        <div className="d-flex align-items-center">
          <Level />
          <p className="ml-2">{info?.level}</p>
        </div>
        <div className="d-flex align-items-center">
          <Employment />
          <p className="ml-2">{info?.employment}</p>
        </div>
        <div className="d-flex align-items-center">
          <E />
          <p className="ml-2">{type}</p>
        </div>
      </div>
      <p>{info?.jobdescription}</p>
      <div className="text-right mt-3">
        <ButtonTransparentArrow to={fields.uri}>
          Bekijk vacature
        </ButtonTransparentArrow>
      </div>
    </StyledPost>
  )
}

export default HighlightedVacancies
